.header {
  position: sticky;
  top: 0;
  z-index: 1;
  color: var(--0);
  background-color: white;
  padding: 2rem 1.25rem 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  transition: padding, background-color;
  transition-duration: 0.3s;
  color: black;
}

.header * {
  transition: width, height, margin, opacity;
  transition-duration: 0.3s;
}

.header[data-variant='offer'],
.header[data-variant='minimal'] {
  background-color: transparent;
}

.header[data-variant='landing'] {
  padding-top: 3.5625rem;
}

.headerEarned {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  height: 2.4375rem;
}

.earned {
  padding: 0.25rem 0.5rem;
  background-color: var(--100);
  color: var(--Blue-Highlight);
  border-radius: 12.5rem;
}

.searchIcon {
  justify-self: start;
  margin-left: 1rem;
  pointer-events: none;
}

.search {
  padding: 0.5rem;
  padding-left: 2.375rem;
  border-radius: 1.5625rem;
  border: 0;
  width: 100%;
  background-color: rgba(238, 242, 246, 1);
}

.search::placeholder {
  color: var(--500);
}

.searchBar {
  display: flex;
  align-items: center;
}

.searchWrapper {
  flex: 1;
}

.backButton {
  margin-right: 1rem;
}

.rightButton {
  margin-left: 1rem;
}

.iconButton {
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  transition: all 0.3s;
}

.header[data-variant='minimal'] .iconButton,
.header[data-variant='offer'] .iconButton {
  background-color: var(--0);
  color: var(--800);
}

.header[data-variant='offer'] .iconButton {
  width: 2.125rem;
  height: 2.125rem;
  box-shadow: var(--Dropshadow);
  border-radius: 100%;
}

.iconButton,
.iconButton:visited {
  color: var(--0);
}

.iconButton>* {
  width: 1.5rem;
  height: 1.5rem;
}

.backIcon {
  transform: scaleX(-1);
}

.zeroHeight,
.zeroWidth,
.zeroOpacity {
  opacity: 0;
}

.zeroWidth,
.zeroHeight {
  overflow: hidden;
}

.zeroHeight {
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.zeroWidth {
  width: 0;
  margin-left: 0;
  margin-right: 0;
}