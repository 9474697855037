@keyframes slideInWithOvershoot {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  70% {
    transform: translateY(-20px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutWithOvershoot {
  0% {
    transform: translateY(0);
    opacity: 1;
    position: absolute;
  }

  30% {
    transform: translateY(-10px);
    opacity: 1;
    position: absolute;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
    position: fixed;
  }
}

.notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 407px;
  width: 390px;
  margin: 0 auto;
  border-radius: 25px 25px 0 0;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.8s;
  background-color: #ffffff;
  padding: 16px 20px 60px;
  z-index: 1002;
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #30313d;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: #30313d;
  line-height: 20px;
  text-align: center;
}

.closeButton {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  align-self: flex-end;
  width: 48px;
  height: 48px;
}

.visible {
  position: absolute;
  animation: slideInWithOvershoot 0.8s ease-out forwards;
  visibility: visible;
  transition: visibility 0s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden {
  animation: slideOutWithOvershoot 0.8s ease-in forwards;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}