.tile {
  display: flex;
  width: 4.6875rem;

  flex-direction: column;
  align-items: center;
  gap: 0.375rem;

  flex-shrink: 0;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.tile {
  --navtile-accent: var(--400);
}

.tile,
.tile:link,
.tile:visited {
  color: var(--navtile-accent);
}

.tile.active {
  --navtile-accent: var(--Deep-Blue);
}
