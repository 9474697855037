::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app>main {
  flex: 1;
  overflow: auto;
}

.background {
  display: none;
}

.backgroundVisible {
  display: block;
  background-color: #000000;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.shopNow {
  background-color: red;
  height: 126px;
}