.nearbyOffers {
  display: grid;
  grid-template:
    'header . link' auto
    'map map map' auto
    / auto 1fr auto;
  gap: 0.5rem;
}

.header {
  grid-area: header;
}

.link {
  grid-area: link;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.map {
  grid-area: map;
  width: 100%;
  height: 6.9375rem;
  border-radius: 0.625rem;
  overflow: hidden;
}

.overlay {
  position: relative;
  align-self: end;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(57, 60, 63, 0) 69.24%, #393c3f 100%);
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: flex-end;
  color: var(--100);
  font-weight: normal;
}
