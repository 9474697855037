.main {
  padding: 0 1.25rem;
  width: 100%;
  height: 100%;
  justify-items: center;
  background-image: url('assets/images/WelcomeBackground.svg');
  background-color: var(--Deep-Blue);
  color: var(--0);
  background-position: center center;
  background-repeat: no-repeat;
  display: grid;
  grid-template-areas: '.' 'logo' '.' 'buttons' '.';
  grid-template-rows: minmax(2rem, 24.9rem) auto minmax(2rem, 1fr) auto 2.5rem;
  grid-template-columns: minmax(auto, var(--frame-width));
  justify-content: center;
}

.logo {
  grid-area: logo;
  padding: 0.75rem 2.5rem;
}

.buttons {
  width: 100%;
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.loginLink,
.loginLink > button {
  width: 100%;
}
