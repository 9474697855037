/* 
 * Scroll the whole page, not just the offer section.
 * that way the page contents will be visible under the transparent header.
 */

:global(main):has(.offer) {
  overflow: visible !important;
}

.banner {
  position: absolute;
  left: 0;
  top: 0;
  height: 12.5rem;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.p1Bold {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  color: #30313D;
}

.text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  color: #30313D;
}

.offer {
  padding: 4rem 0 2rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo {
  align-self: start;
  margin-left: 1.4rem;
  bottom: 0.3rem;
  position: relative;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  background-color: var(--0);
  padding: 0 1.25rem;
}

.map {
  margin-top: 0.25rem;
  width: 100%;
  height: 6.9375rem;
  border-radius: 0.625rem;
  overflow: hidden;
}

.summary,
.cashback,
.tnc,
.hours {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.summary,
.tnc {
  gap: 0.25rem;
}

.cashback {
  gap: 0.5rem;
}

.hours {
  gap: 0.75rem;
}

.deal {
  display: flex;
  gap: 5px;
  padding: 1rem;
  font-weight: 700;
  border: 1px solid #E3E8EE;
  color: #30313D;
  border-radius: 0.625rem;
}

.cashbackIcon {
  margin-right: 0.5rem;
}

.light {
  color: var(--600);
}

.dayHours {
  display: flex;
  justify-content: space-between;
}

.day {
  text-transform: capitalize;
}