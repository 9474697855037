.mapPage {
  position: relative;
  overflow: hidden;
  height: 800px;

}

.carousel {
  align-self: end;
  bottom: 5%;
}