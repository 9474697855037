:root {
  --900: rgba(18, 19, 21, 1);
  --800: rgba(29, 32, 34, 1);
  --700: rgba(57, 60, 63, 1);
  --600: rgba(98, 98, 109, 1);
  --500: rgba(141, 141, 148, 1);
  --400: rgba(161, 161, 168, 1);
  --300: rgba(207, 206, 210, 1);
  --200: rgba(224, 225, 228, 1);
  --100: rgba(239, 240, 243, 1);
  --75: rgba(245, 245, 245, 1);
  --50: rgba(247, 248, 250, 1);
  --0: rgba(255, 255, 255, 1);
  --Kard-Blue: rgba(52, 84, 226, 1);
  --Royal-Blue: rgba(7, 102, 235, 1);
  --Brite-Blue: rgba(62, 181, 255, 1);
  --Deep-Blue: rgba(3, 39, 109, 1);
  --Purple: rgba(118, 87, 245, 1);
  --Pink: rgba(241, 155, 249, 1);
  --Orange: rgba(241, 155, 66, 1);
  --Mauve: rgba(110, 35, 92, 1);
  --Icon: rgba(203, 203, 221, 1);
  --Green: rgba(95, 155, 144, 1);
  --Blue-Highlight: color-mix(in srgb, var(--Deep-Blue) 90%, var(--0));
  --Green-Light: rgba(209, 250, 223, 1);
  --Light-Blue: rgba(229, 237, 253, 1);
  --frame-width: 24.375rem;
  --frame-height: 52.75rem;
  font-size: 16px;
  --border: 0.0625rem solid var(--100);
  --Dropshadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

body {
  color: var(--700);
  background-color: var(--0);
  accent-color: var(--Deep-Blue);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
}

a:link {
  color: var(--Kard-Blue);
}

a:visited {
  color: var(--Purple);
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  background-color: var(--75);
}

#root {
  width: var(--frame-width);
  height: var(--frame-height);
  max-width: 100%;
  max-height: 100%;
  background-color: var(--0);
  resize: both;
  overflow: auto;
  position: relative;
}

input,
button,
textarea,
select {
  color: var(--800);
}
