@font-face {
  font-display: "SF Pro Display";
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/SF-Pro-Display-Bold.otf') format('otf');
}

* {
  font-family: 'SF Pro Display', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.h1-bold {
  font-size: 1.75rem;
  /* 27.65px */
  font-weight: 700;
  line-height: 2;
  letter-spacing: 0.005em;
}

.h1 {
  font-size: 1.7281rem;
  /* 27.65px */
  font-weight: 700;
  line-height: 1.4;
  color: #30313D;
}

.p1-bold {
  font-size: 1.125rem;
  /* 16px */
  font-weight: 700;
  line-height: 1.5;
}

.p1-medium {
  font-size: 1rem;
  /* 16px */
  font-weight: 500;
  line-height: 1.5;
}

.p1 {
  font-size: 1rem;
  /* 16px */
  font-weight: 400;
  line-height: 1.5;
}

.caption-bold {
  font-size: 0.875rem;
  /* 14px */
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.caption-medium {
  font-size: 0.875rem;
  /* 14px */
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.caption {
  font-size: 0.875rem;
  /* 14px */
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.tiny-bold {
  font-size: 0.75rem;
  /* 12px */
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.tiny-medium {
  font-size: 0.75rem;
  /* 12px */
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.tiny {
  font-size: 0.75rem;
  /* 12px */
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.very-tiny {
  font-size: 0.5rem;
  /* 8px */
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

.shadowed {
  box-shadow: var(--Dropshadow);
}

.center-everything {
  display: grid;
  grid-template: 'main' 1fr / 1fr;
  align-items: center;
  justify-items: center;
}

.center-everything>* {
  grid-area: main;
}