.topSection {
  height: 17.25rem;
  padding-top: 3.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.rewards {
  display: flex;
  flex-direction: column;
  height: 4.125rem;
  gap: 0.125rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-top: 1.25rem;
}

.rewardsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
  background: linear-gradient(76.11deg, #03276D 2.46%, #064BD3 106.23%);
  border-radius: 0.9375rem;
  gap: 2.5rem;
}

.balanceContent {
  width: 8.4375rem;
  height: 3.4375rem;
  gap: 0.125rem;
}

.balanceTitle {
  font-weight: 600;
  line-height: 1.25;
  font-size: 0.688rem;
}

.balanceAmount {
  font-weight: 700;
  line-height: 2.088rem;
  font-size: 1.75rem;
}

.balanceActions {
  height: 1.75rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.balanceAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  gap: 0.375rem;
  width: 8.9375rem;
  height: 1.75rem;
  border-radius: 1.5625rem;
  background-color: rgba(255, 255, 255, 0.25);
}

.balanceActionText {
  color: white;
  font-weight: 600;
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.rewardsDescription {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(106, 115, 131, 1);
}

.learnMore {
  color: rgba(106, 115, 131, 1);
  text-decoration: underline;
  cursor: pointer;
}

.transactions {
  background-color: var(--0);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.linkWithIcon {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  gap: 0.25rem;
}

.logos {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  overflow-x: scroll;
  flex-shrink: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 1rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.logos::-webkit-scrollbar {
  display: none;
}

.offers {
  width: 10.6875rem;
  height: 7.875rem;
  min-width: 10.6875rem;
  flex: 0 0 10.6875rem;
  background-color: rgba(238, 242, 246, 1);
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
  border: 1px solid rgba(246, 248, 250, 1);
}

.offerContent {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.divider {
  height: 0.375rem;
  background-color: rgba(246, 248, 250, 1);
  margin: 1rem 0;
  width: 100%;
}

.offerCommission {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: rgba(48, 49, 61, 1);
  align-self: flex-start;
}

.offerImage {
  width: 3rem;
  height: 3rem;
  border-radius: 12.5rem;
}

.offerName {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(48, 49, 61, 1);
  align-self: flex-start;
}

.transaction {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  overflow-x: scroll;
}

.transactionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-bottom: 0.0625rem solid var(--50);
  gap: 0.5rem;
}

.transactionImage {
  width: 2.25rem;
  height: 2.25rem;
}

.transactionContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}

.transactionInfo {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.transactionName {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.transactionDate {
  color: var(--600);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
}

.transactionAmount {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}