.pin {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.clickable {
  pointer-events: all;
  cursor: pointer;
}
