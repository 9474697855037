.button {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.375rem;
  border: 0;
  transition: background-color 0.2s;
  border-radius: 25px;
}

button:not(.disabled) {
  cursor: pointer;
}

button:disabled,
form:invalid>button[type='submit'],
.disabled {
  color: var(--500);
  background-color: var(--100);
}

.primary {
  color: white;
  background-color: #3454e2;
}

.secondary {
  color: #30313d;
  border: 1px solid #d5dbe1;
  background-color: white;
}

.large {
  width: 21.875rem;
  height: 2.625rem;
}

.medium {
  width: 12.5rem;
  height: 2.625rem;
}

.small {
  width: 6.25rem;
  height: 2.625rem;
}