.header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}

.logo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 5px;
}

.button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 164px;
    height: 24px;
    border-radius: 25px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
    gap: 4px;
    border: 1px solid #D5DBE1;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 1);
}

.buttonText {
    font-size: 0.75rem;
    font-weight: 600;
    color: #374151;
}

.logoText {
    font-size: 14px;
    font-weight: 600;
    color: #30313D;
    line-height: 20px;
}

.connecting {
    font-size: 0.75rem;
    font-weight: 600;
    color: #374151;
}

.error {
    background-color: #DF1B41;
    color: white;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}