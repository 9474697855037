.label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input {
  height: 3rem;
  border-radius: 0.375rem;
  padding: 0.75rem;
  border: 0.0625rem solid var(--400);
  background: var(--0);
  width: 100%;
}

.input[type='password'] {
  /* font-size: 3.3em; */
  letter-spacing: -0.05em;
}

.wrapper {
  position: relative;
}

.input:focus {
  outline: none;
  border-color: var(--Kard-Blue);
}
