.toastContainer {
  position: absolute;
  width: 90%;
  max-width: 30rem;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
}

.toast {
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
  color: var(--800);
  background: var(--0, #fff);
  padding: 0;
}

.toastBody > div:first-child {
  height: 100%;
}

.toastBody {
  padding: 0;
  margin: 0;
}

@keyframes slide {
  0% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

.enter,
.exit {
  animation: slide 0.4s cubic-bezier(0.25, 1, 0.5, 1) both;
}

.exit {
  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-direction: reverse;
}
