.button {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.375rem;
  border: 0;
  transition: background-color 0.2s;
}

button:not(.disabled) {
  cursor: pointer;
}

button:disabled,
form:invalid > button[type='submit'],
.disabled {
  color: var(--500);
  background-color: var(--100);
}

.primary {
  color: var(--0);
  background-color: var(--Deep-Blue);
}

.secondary {
  color: var(--Deep-Blue);
  background-color: var(--0);
}
