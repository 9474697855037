.main {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.content {
    height: 100%;
    width: 100%;
}