.overlayContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F6F8FA;
    transform: translateY(-100%);
    transition: transform 0.5s ease;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.kardIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    opacity: 0;
    transform: translateY(-20px);
}

.visible .kardIcon {
    animation: dropAndBounce 0.6s ease forwards;
}

@keyframes dropAndBounce {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    70% {
        opacity: 1;
        transform: translateY(5px);
    }

    85% {
        transform: translateY(-2px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.visible {
    transform: translateY(0);
}

.logoText {
    font-size: 14px;
    font-weight: 600;
    color: #30313D;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.contentText {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #30313D;
}

.spanContent {
    font-size: 14px;
    font-weight: 400;
    color: #6A7383;
    line-height: 20px;
    text-align: center;
}

.amount {
    color: #3454E2;
    font-weight: 700;
    font-size: 18px;
}