.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  max-width: 100%;
  gap: 0.3125rem;
  padding: 0.5rem;
  transition:
    transform 0.3s,
    opacity 0.3s;
  position: relative;
}

.carousel:empty {
  opacity: 0;
  transform: scale(0.95) translateX(10%);
}

.carousel > * {
  flex-basis: calc(100% - 2.5rem);
  flex-shrink: 0;
  scroll-snap-align: center;
}

.carousel::before,
.carousel::after {
  visibility: hidden;
  content: '';
  flex: 0 0 2.5rem;
}

.carousel::-webkit-scrollbar {
  display: none;
}
