.offersList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.offersList>* {
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1),
    opacity 0.3s ease-out;
  transform-origin: top center;
  will-change: transform, opacity;
}

.row {
  grid-template-columns: repeat(2, 1fr);
}

.row>* {
  animation: cardEnter 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes cardEnter {
  from {
    opacity: 0.6;
    transform: scale(0.98) translateY(5px);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}