.availableOffers {
  width: 100%;
}

.availableOffersHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.availableOffersHeaderButton {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 200px;
  border: 1px solid rgba(213, 219, 225, 1);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availableOffersCategories {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 12px;
}

.availableOffersCategories::-webkit-scrollbar {
  display: none;
}

.availableOffersCategoryButton {
  flex: 0 0 auto;
  padding: 4px 8px 4px 8px;
  border: 1px solid rgba(238, 242, 246, 1);
  height: 30px;
  border-radius: 25px;
  background: rgba(238, 242, 246, 1);
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: rgba(48, 49, 61, 1);
}

.availableOffersCategoryButton.active {
  background: rgba(48, 49, 61, 1);
  color: white;
  border-color: rgba(48, 49, 61, 1);
}