.location {
  cursor: pointer;
  background: var(--0);
  border-radius: 0.3125rem;
  border: var(--border);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  text-align: left;
  align-items: center;
}

.logoStoreDetails {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.logo {
  width: 2.875rem;
  height: 2.875rem;
}

.storeDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.details {
  color: var(--600);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.distance {
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  color: var(--500);
}
