.section {
    height: 126px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #6A7383;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    gap: 8px;
}

.button {
    background-color: #3454E2;
    width: 350px;
    height: 42px;
    border-radius: 25px;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    gap: 8px;
    color: #FFFFFF;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
}