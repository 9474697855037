.reward {
  padding-top: 2.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6875rem;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 19rem;
  align-items: center;
  text-align: center;
}

.date {
  white-space: nowrap;
}
