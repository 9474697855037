.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem 0rem 2rem 0rem;
  border-top: var(--border);
  height: 98px;
}

.footer {
  transition:
    height 0.3s,
    opacity 0.5s;
  height: 6.125rem;
  background-color: var(--0);

}

.hidden {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}