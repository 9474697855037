.main {
  padding: 0 1.25rem;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: '.' 'title' '.' 'inputs' '.' 'links' '.';
  grid-template-rows: minmax(2rem, 5.5rem) auto 2rem auto 2rem auto minmax(
      2rem,
      1fr
    );
  grid-template-columns: minmax(auto, var(--frame-width));
  justify-content: center;
}

.title {
  grid-area: title;
}

.inputs {
  width: 100%;
  grid-area: inputs;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputs > :nth-child(2n + 3) {
  margin-top: 0.5rem;
}

.links {
  grid-area: links;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
