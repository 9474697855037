.offerGrid {
  cursor: pointer;
  background: var(--0);
  border-radius: 0.4375rem;
  border: var(--border);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  text-align: left;
}

.offerRow {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(227, 232, 238, 1);
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  height: fit-content;
}

.logoStoreDetails {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.offerName {
  font-size: 12px;
  font-weight: 400;
  color: rgba(48, 49, 61, 1);
  margin-bottom: 1rem;
}

.storeDetailsRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.storeDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.detail {
  background-color: #EBEEF1;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.details {
  color: var(--600);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.cashbackArrow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cashback {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
}

.cashbackRow {
  display: flex;
  flex-direction: row;
  color: rgba(48, 49, 61, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  gap: 2px;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}

.arrow {
  width: 1.125rem;
  height: 1.125rem;
}