.overlayContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F6F8FA;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    visibility: hidden;
}

.icon {
    width: 152px;
    height: 146px;
}

.kardIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.visible {
    opacity: 1;
    visibility: visible;
}

.logoText {
    font-size: 14px;
    font-weight: 600;
    color: #30313D;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.contentText {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #30313D;
}

.spanContent {
    font-size: 14px;
    font-weight: 400;
    color: #6A7383;
    line-height: 20px;
    text-align: center;
}

.amount {
    color: #3454E2;
    font-weight: 700;
    font-size: 18px;
}