.main {
  padding: 3.5625rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.button {
  width: 100%;
  background: linear-gradient(76.11deg, #03276D 2.46%, #064BD3 106.23%);
}